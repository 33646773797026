import { combineReducers } from 'redux';

import header from './headerReducer';
import wtd from './wtdReducer';
import user from './userReducer';
import country from './countryReducer';
import territory from './territoryReducer';
import client from './clientReducer';
import site from './siteReducer';
import infocode from './infocodeReducer';
import router from './routerReducer';
import plc from './plcReducer';
import charts from './chartsReducer';
import events from './eventsReducer';
import rgn from './rgnReducer';

const rootReducer = combineReducers({
  header,
  wtd,
  user,
  country,
  client,
  site,
  infocode,
  router,
  territory,
  plc,
  charts,
  events,
  rgn
});

export default rootReducer;
