import rgn from '../store/rgnState';

const rgnReducer = (state = rgn, action) => {
  switch (action.type) {
    case 'RGN_LOAD_DATA':
      return {
        ...state, data: action.data
      }
    default:
      return { ...state };
  }
};

export default rgnReducer;
