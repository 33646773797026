export default {
    loading: true,
    loadingCSV: false,
    data: [],
    options: [],
    headerValues: [],
    content: {
        blue: {
          name: 'Select Info Code',
          value: '-',
          dataset: []
        },
        cyan: {
          name: 'Select Info Code',
          value: '-',
          dataset: []
        },
        yellow: {
          name: 'Select Info Code',
          value: '-',
          dataset: []
        },
        red: {
          name: 'Select Info Code',
          value: '-',
          dataset: []
        }
    }
}