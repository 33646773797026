export default {
    saved: false,
    data: [],
    fetched: false,
    name: "",
    site: "",
    formSelect: [],
    plc: [],
    wpmiKeys: [],
    rgnId:'',
    wpmiKey:'',
    notification: null
};