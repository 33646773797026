import infocode from '../store/infocodeState';

const infocodeReducer = (state = infocode, action) => {
  switch (action.type) {
    case 'INFOCODE_LOAD_DATA':
      return {
        ...state, data: action.data, fetched: true
      }
    case 'INFOCODE_FORM_LOAD':
      return {
        ...state, 
        name: action.form.name,
        codeNumber: action.form.codeNumber,
        codeType: action.form.codeType,
        scaleFactor: action.form.scaleFactor,
        unitName: action.form.unitName,
        maximum: action.form.maximum,
        minimum: action.form.minimum,
        swcKeys: action.form.swcKeys
      }
    case 'INFOCODE_FIELD_CHANGE':
      return {
        ...state, [action.field]: action.data
      }
    case 'TOGGLE_INFOCODE_SAVED':
      return {
        ...state, infocodeSaved: action.infocodeSaved
      }
    default:
      return { ...state };
  }
};

export default infocodeReducer;
