import site from '../store/siteState';

const siteReducer = (state = site, action) => {
  switch (action.type) {
    case 'SITE_LOAD_DATA':
      return {
        ...state, data: action.data, fetched: true
      }
    case 'SITE_FORM_LOAD':
      return {
        ...state, 
        name: action.form.name,
        client: action.form.parentId,
        territory: action.form.meta && action.form.meta.territoryId       ? action.form.meta.territoryId : '',
        country: action.form.meta   && action.form.meta.countryId         ? action.form.meta.countryId : '',
        //client: action.form.meta    && action.form.meta.clientId            ? action.form.meta.clientId : '',
        location: action.form.meta  && action.form.meta.location          ? action.form.meta.location : '',
        address: action.form.meta   && action.form.meta.address           ? action.form.meta.address : '',
        gps: action.form.meta       && action.form.meta.gps               ? action.form.meta.gps : '',
        alert: action.form.meta     && action.form.meta.alert             ? action.form.meta.alert : false,
        alarm: action.form.meta     && action.form.meta.alarm             ? action.form.meta.alarm : false,
        other: action.form.meta     && action.form.meta.other             ? action.form.meta.other : '',
      }
    case 'SITE_FIELD_CHANGE':
      return {
        ...state, [action.field]: action.data
      }
    case 'SITE_SELECT_DATA':
      return {
        ...state, formSelect: action.data
      }
    case 'SITE_SHOW_ERROR':
      const { path, message } = action;
      return {
        ...state, path, message
      }
    case 'SET_SITE_CHILDREN':
      return {
        ...state, [action.key]: action.value
      }
    case 'TOGGLE_SITE_SAVED':
      return {
        ...state, regionSaved: action.regionSaved
      }
    default:
      return { ...state };
  }
};

export default siteReducer;
