import client from '../store/clientState';

const clientReducer = (state = client, action) => {
  switch (action.type) {
    case 'CLIENT_LOAD_DATA':
      return {
        ...state, data: action.data, fetched: true
      }
    case 'CLIENT_FORM_LOAD':
      return {
        ...state, 
        name: action.form.name,
        territory: action.form.parentId || '',
        country: action.form.meta ? action.form.meta.gparentId : '',
        address: action.form.meta ? action.form.meta.address : '',
        contact_userId: action.form.meta ? action.form.meta.contact_userId : '',
       
      }
    case 'CLIENT_FIELD_CHANGE':
      return {
        ...state, [action.field]: action.data
      }
    case 'CLIENT_SHOW_NOTIFICATION':
      return {
        ...state, notification : action.notification
      }
    case 'CLIENT_RESET_NOTIFICATION':
      return {
        ...state, notification : null
      }
    case 'CLIENT_SELECT_DATA':
      return {
        ...state, [action.name]: action.data
      }
    case 'SET_CLIENT_CHILDREN':
      return {
        ...state, [action.key]: action.value
      }
    case 'TOGGLE_CLIENT_SAVED':
      return {
        ...state, regionSaved: action.regionSaved
      }
    default:
      return { ...state };
  }
};

export default clientReducer;
