import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import {RequireAuth} from './utils/Authentication';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Email App
const TheEmailApp = React.lazy(() => import('./views/apps/email/TheEmailApp'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const ForgotPassword = React.lazy(() => import('./views/pages/forgotPassword/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./views/pages/resetPassword/ResetPassword'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const Uptime = React.lazy(() => import('./views/pages/uptime/Uptime'));
const ReactFlow = React.lazy(() => import('./views/pages/reactFlow/ReactFlow'));

class App extends Component {
  render() {
    return (
      <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/forgot-password" name="Forgot password Page" render={props => <ForgotPassword {...props}/>} />
              <Route exact path="/reset-password" name="Reset password Page" render={props => <ResetPassword {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route exact path="/uptime" name="Page for Uptime" render={props => <Uptime {...props}/>} />
              <Route exact path="/react-flow" name="React Flow" render={props => <ReactFlow {...props}/>} />
              <RequireAuth>
                <Route path="/apps/email" name="Email App" render={props => <TheEmailApp {...props}/>} />
                <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
              </RequireAuth>
            </Switch>
          </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
