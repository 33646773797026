import charts from '../store/chartsState';

const chartsReducer = (state = charts, action) => {
  let content;
  let localContent = JSON.parse( localStorage.getItem('content') );
  switch (action.type) {
    case 'GRAPH_LOAD_DATA':
      return {
        ...state, data: action.data
      }
    case 'SET_INFOCODE_OPTIONS':
        return {
          ...state, options: action.options
        }
    case 'LOAD_HEADER_VALUES':
      return {
        ...state, headerValues: action.headerValues
      }
    case 'CHANGE_TOPGRAPH_HEADER': 
      content = {
        ...state.content,
        ...localContent,
        [action.color]: {
          ...state.content[action.color],
          name: action.item.code.name,
          code: action.item.code.codeNumber,
          color: action.color,
        }
      }
      localStorage.setItem( 'content', JSON.stringify(content) );
      return {
        ...state, 
        content
      }
    case 'LOAD_TOPGRAPH_VALUES':
      content = {
        ...state.content,
        ...localContent,
        [action.color]: {
          ...state.content[action.color],
          name: localContent[action.color].name,
          code: localContent[action.color].code,
          dataset: action.data,
          value: action.data[action.data.length-1]
        }
      }
      localStorage.setItem( 'content', JSON.stringify(content) );
      return {...state,
        content
      }
    case 'RESET_TOPGRAPH_VALUES':
      content = {
        ...state.content,
        ...localContent,
        [action.color]: {
          ...state.content[action.color],
          value: '-',
          dataset: [],
        },
      }
      localStorage.setItem( 'content', JSON.stringify(content) );
      return {...state,
        content
      }
    case 'GRAPH_LOAD_DATA_REQUEST':
      return {...state,
        loading: action.loading
      }
    case 'GRAPH_LOAD_CSV_REQUEST':
      return {...state,
        loadingCSV: action.loading
      }
    default:
      return { ...state };
  }
};

export default chartsReducer;
