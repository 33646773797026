export default {
    data: [],
    fetched: false,
    path: "",
    message: "",
    address: "",
    location: "",
    gps: "",
    alert: false,
    alarm: false,
    other: "",
    formSelect: []
};