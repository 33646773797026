import router from '../store/routerState';

const hubReducer = (state = router, action) => {
  switch (action.type) {
    case 'HUB_LOAD_DATA':
      return {
        ...state, data: action.data, fetched: true
      }
    case 'HUB_FORM_LOAD':
      return {
        ...state, 
        serialNumber: action.form.serialNumber, 
        certName: action.form.certName, 
        ipAddr: action.form.ipAddr, 
        rgnId: action.form.rgnId, 
        published: action.form.published, 
      }
    case 'HUB_FIELD_CHANGE':
      return {
        ...state, [action.field]: action.data
      }
    case 'HUB_SELECT_DATA':
      return {
        ...state, formSelect: action.data
      }
    case 'HUB_SHOW_ERROR':
      const { path, message } = action;
      return {
        ...state, path, message
      }
    case 'TOGGLE_HUB_SAVED':
      return {
        ...state, hubSaved: action.hubSaved
      }
    default:
      return { ...state };
  }
};

export default hubReducer;
