import plc from '../store/plcState';

const plcReducer = (state = plc, action) => {
  switch (action.type) {
    case 'PLC_LOAD_DATA':
      return {
        ...state, data: action.data, fetched: true
      }
    case 'PLC_FORM_LOAD':
      return {
        ...state,
        name: action.form.name,
        unitId: action.form.unitId,
        ceNumber: action.form.ceNumber,
        ipAddr: action.form.ipAddr,
        published: action.form.published,
        icgId: action.form.icgId,
        hubId: action.form.hubId,
        swcKey: action.form.swcKey,
        swVersion: action.form.swVersion,
        fwVersion: action.form.fwVersion,
        ipMask: action.form.ipMask,
        ipGate: action.form.ipGate,
        wtd: action.form.wtd,
        hub: action.form.hub
      }
    case 'PLC_EXTRA_DATA':
      return {
        ...state,
        ipMask: action.data.ipMask,
        ipGate: action.data.ipGate
      }
    case 'PLC_FIELD_CHANGE':
      return {
        ...state, [action.field]: action.data
      }
    case 'PLC_SHOW_ERROR':
      const { path, message } = action;
      return {
        ...state, path, message
      }
    case 'SOFTWARE_LOAD_DATA': 
      return {
        ...state, swCodeList: action.swCodeList
      }
    case 'TOGGLE_PLC_SAVED':
      return {
        ...state, plcSaved: action.plcSaved
      }
    default:
      return { ...state };
  }
};

export default plcReducer;
