export default {
    sidebarShow: true,
    asideShow: false,
    darkMode: false,
    topTitle: '',
    topTitleCenter: '',
    regionValues: {
        country: '',
        client: '',
        territory: '',
        site: '',
    }
};