import user from '../store/userState';

const userReducer = (state = user, action) => {
  switch (action.type) {
    case 'USER_LOAD_DATA':
      return {
        ...state, data: action.data, fetched: true
      }
    case 'USER_FORM_LOAD':
      return {
        ...state, 
        name: action.form.name,
        nameUser: action.form.nameUser,
        email: action.form.email,
        role: action.form.role,
        password: action.form.password,
        phone: action.form.phone,
        country: action.form.country,
        territory: action.form.territory,
        created: action.form.created,
        sms: action.form.sms ? action.form.sms : false,
        mail: action.form.mail ? action.form.mail : false,
      }
    case 'USER_FIELD_CHANGE':
      return {
        ...state, [action.field]: action.data
      }
    case 'USER_SHOW_ERROR':
      return {
        ...state, notification : action
      }
    case 'RESET_NOTIFICATION':
      return {
        ...state, notification : null
      }
    case 'USER_RGN_LOAD_DATA':
      return {
        ...state, ListRgn: action.data
      }
    case 'USER_RESET_RGN_DATA':
      return {
        ...state, ListRgn: null
      }
    case 'TERRITORY_LOAD_DATA':
      return {
        ...state, ListTerritory: action.data
      }
    case 'USER_SHOW_NOTIFICATION':
      return {
        ...state, notification : action.notification
      }
    case 'USER_RESET_NOTIFICATION':
      return {
        ...state, notification : null
      }
    case 'SET_USER':
      return {
        ...state, user: action.data
      }
    default:
      return { ...state };
  }
};

export default userReducer;
