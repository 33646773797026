export default {
    data: [],
    ListRgn: [],
    user: null,
    fetched: false,
    name: "",
    email: "",
    password: "",
    role: "",
    phone: "",
    country: "",
    territory: "",
    created: "",
    sms: false,
    mail: false,
    formSelect: [],
    notification: null
};