import event from '../store/eventState';

const eventReducer = (state = event, action) => {
  switch (action.type) {
  	case 'TOGGLE_EVENT_LOADER':
  		return { ...state, isLoadingEvents: !state.isLoadingEvents }
    case 'LOAD_EVENTS':
          return { ...state, 
            deviceData: action.deviceData,
            continuation: action.continuation 
        }
    case 'LOAD_STATS':
      return { ...state, 
        deviceStats: action.deviceStats,
    }
    default:
      return { ...state };
  }
};

export default eventReducer;
