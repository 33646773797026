import header from '../store/headerState';

const headerReducer = (state = header, action) => {
  switch (action.type) {
  	case 'TOGGLE_ASIDE':
  		return { ...state, asideShow: !action.asideShow }
  	case 'TOGGLE_DARK_MODE':
  		return { ...state, darkMode: !action.darkMode }
  	case 'TOGGLE_SIDEBAR':
  		return { ...state, sidebarShow: action.sidebarShow }
	case 'CHANGE_TOP_TITLE':
		return { ...state, topTitle: action.topTitle }
	case 'CHANGE_TOP_TITLE_CENTER':
		return { ...state, topTitleCenter: action.topTitleCenter }	
	case 'CHANGE_ASIDE':
		return { ...state, regionValues: action.objectValues }
	case 'RESET_ASIDE':
		return { ...state, regionValues: {country: '', territory: '', client: '', site: ''} }
    default:
      return { ...state };
  }
};

export default headerReducer;
