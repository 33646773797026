export default {
    data: [],
    fetched: false,
    name: '',
    unitId: '',
    ceNumber: '',
    ipAddr: '',
    icgId: '',
    published: false,
    hub: {},
    wtd: {},
    hubId: '',
    swcKey: '',
    swVersion: '',
    fwVersion: '',
    swCodeList: [],
    ipMask: '',
    ipGate: ''
};