import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'

import {
  cibSkype,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex
} from '@coreui/icons'
import {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
} from '@coreui/icons'
import {
  cil3d,
cil4k,
cilAccountLogout,
cilActionRedo,
cilActionUndo,
cilAddressBook,
cilAirplaneModeOff,
cilAirplaneMode,
cilAirplay,
cilAlarm,
cilAlbum,
cilAlignCenter,
cilAlignLeft,
cilAlignRight,
cilAmericanFootball,
cilAnimal,
cilAperture,
cilApple,
cilApplicationsSettings,
cilApplications,
cilAppsSettings,
cilApps,
cilArrowBottom,
cilArrowCircleBottom,
cilArrowCircleLeft,
cilArrowCircleRight,
cilArrowCircleTop,
cilArrowLeft,
cilArrowRight,
cilArrowThickBottom,
cilArrowThickFromBottom,
cilArrowThickFromLeft,
cilArrowThickFromRight,
cilArrowThickFromTop,
cilArrowThickLeft,
cilArrowThickRight,
cilArrowThickToBottom,
cilArrowThickToLeft,
cilArrowThickToRight,
cilArrowThickToTop,
cilArrowThickTop,
cilArrowTop,
cilAssistiveListeningSystem,
cilAsteriskCircle,
cilAsterisk,
cilAt,
cilAudioDescription,
cilAudioSpectrum,
cilAudio,
cilAvTimer,
cilBabyCarriage,
cilBaby,
cilBackspace,
cilBadge,
cilBalanceScale,
cilBan,
cilBank,
cilBarChart,
cilBarcode,
cilBaseball,
cilBasket,
cilBasketball,
cilBath,
cilBathroom,
cilBattery0,
cilBattery3,
cilBattery5,
cilBatteryAlert,
cilBatteryEmpty,
cilBatteryFull,
cilBatterySlash,
cilBeachAccess,
cilBeaker,
cilBed,
cilBellExclamation,
cilBell,
cilBike,
cilBirthdayCake,
cilBlind,
cilBluetooth,
cilBlurCircular,
cilBlurLinear,
cilBlur,
cilBoatAlt,
cilBold,
cilBoltCircle,
cilBolt,
cilBook,
cilBookmark,
cilBorderAll,
cilBorderBottom,
cilBorderClear,
cilBorderHorizontal,
cilBorderInner,
cilBorderLeft,
cilBorderOuter,
cilBorderRight,
cilBorderStyle,
cilBorderTop,
cilBorderVertical,
cilBowling,
cilBraille,
cilBriefcase,
cilBrightness,
cilBritishPound,
cilBrowser,
cilBrushAlt,
cilBrush,
cilBug,
cilBuilding,
cilBullhorn,
cilBurger,
cilBurn,
cilBusAlt,
cilCalculator,
cilCalendarCheck,
cilCalendar,
cilCameraControl,
cilCameraRoll,
cilCamera,
cilCarAlt,
cilCaretBottom,
cilCaretLeft,
cilCaretRight,
cilCaretTop,
cilCart,
cilCash,
cilCasino,
cilCast,
cilCat,
cilCc,
cilCenterFocus,
cilChartLine,
cilChartPie,
cilChart,
cilChatBubble,
cilCheckAlt,
cilCheckCircle,
cilCheck,
cilChevronBottom,
cilChevronCircleDownAlt,
cilChevronCircleLeftAlt,
cilChevronCircleRightAlt,
cilChevronCircleUpAlt,
cilChevronDoubleDown,
cilChevronDoubleLeft,
cilChevronDoubleRight,
cilChevronDoubleUp,
cilChevronLeft,
cilChevronRight,
cilChevronTop,
cilChildFriendly,
cilChild,
cilCircle,
cilClearAll,
cilClipboard,
cilClock,
cilClone,
cilClosedCaptioning,
cilCloudDownload,
cilCloudUpload,
cilCloud,
cilCloudy,
cilCode,
cilCoffee,
cilCog,
cilColorBorder,
cilColorFill,
cilColorPalette,
cilColumns,
cilCommand,
cilCommentBubble,
cilCommentSquare,
cilCompass,
cilCompress,
cilContact,
cilContrast,
cilControl,
cilCopy,
cilCouch,
cilCreditCard,
cilCropRotate,
cilCrop,
cilCursorMove,
cilCursor,
cilCut,
cilDataTransferDown,
cilDataTransferUp,
cilDeaf,
cilDelete,
cilDescription,
cilDevices,
cilDialpad,
cilDiamond,
cilDinner,
cilDisabled,
cilDog,
cilDollar,
cilDoor,
cilDoubleQuoteSansLeft,
cilDoubleQuoteSansRight,
cilDrinkAlcohol,
cilDrink,
cilDrop,
cilEco,
cilEducation,
cilElevator,
cilEnvelopeClosed,
cilEnvelopeLetter,
cilEnvelopeOpen,
cilEqualizer,
cilEthernet,
cilEuro,
cilExcerpt,
cilExitToApp,
cilExpandDown,
cilExpandLeft,
cilExpandRight,
cilExpandUp,
cilExposure,
cilExternalLink,
cilEyedropper,
cilFaceDead,
cilFace,
cilFactorySlash,
cilFactory,
cilFastfood,
cilFax,
cilFeaturedPlaylist,
cilFile,
cilFilterFrames,
cilFilterPhoto,
cilFilterSquare,
cilFilterX,
cilFilter,
cilFindInPage,
cilFingerprint,
cilFire,
cilFlagAlt,
cilFlightTakeoff,
cilFlipToBack,
cilFlipToFront,
cilFlip,
cilFlower,
cilFolderOpen,
cilFolder,
cilFont,
cilFootball,
cilFork,
cilFridge,
cilFrown,
cilFullscreenExit,
cilFullscreen,
cilFunctionsAlt,
cilFunctions,
cilGamepad,
cilGarage,
cilGem,
cilGif,
cilGift,
cilGlobeAlt,
cilGolfAlt,
cilGolf,
cilGradient,
cilGrain,
cilGraph,
cilGridSlash,
cilGrid,
cilGroup,
cilHamburgerMenu,
cilHandPointDown,
cilHandPointLeft,
cilHandPointRight,
cilHandPointUp,
cilHappy,
cilHd,
cilHdr,
cilHeader,
cilHeadphones,
cilHealing,
cilHeart,
cilHighlighter,
cilHighligt,
cilHistory,
cilHome,
cilHospital,
cilHotTub,
cilHouse,
cilHttps,
cilImageBroken,
cilImagePlus,
cilImage,
cilInbox,
cilIndentDecrease,
cilIndentIncrease,
cilIndustrySlash,
cilIndustry,
cilInfinity,
cilInfo,
cilInputHdmi,
cilInputPower,
cilInput,
cilInstitution,
cilItalic,
cilJustifyCenter,
cilJustifyLeft,
cilJustifyRight,
cilKeyboard,
cilLan,
cilLanguage,
cilLaptop,
cilLayers,
cilLeaf,
cilLemon,
cilLevelDown,
cilLevelUp,
cilLibraryAdd,
cilLibraryBuilding,
cilLibrary,
cilLifeRing,
cilLightbulb,
cilLineSpacing,
cilLineStyle,
cilLineWeight,
cilLinkAlt,
cilLinkBroken,
cilLink,
cilListFilter,
cilListHighPriority,
cilListLowPriority,
cilListNumberedRtl,
cilListNumbered,
cilListRich,
cilList,
cilLocationPin,
cilLockLocked,
cilLockUnlocked,
cilLocomotive,
cilLoop1,
cilLoopCircular,
cilLoop,
cilLowVision,
cilMagnifyingGlass,
cilMap,
cilMediaEject,
cilMediaPause,
cilMediaPlay,
cilMediaRecord,
cilMediaSkipBackward,
cilMediaSkipForward,
cilMediaStepBackward,
cilMediaStepForward,
cilMediaStop,
cilMedicalCross,
cilMeh,
cilMemory,
cilMenu,
cilMic,
cilMicrophone,
cilMinus,
cilMobileLandscape,
cilMobile,
cilMoney,
cilMonitor,
cilMoodBad,
cilMoodGood,
cilMoodVeryBad,
cilMoodVeryGood,
cilMoon,
cilMouse,
cilMouthSlash,
cilMove,
cilMovie,
cilMugTea,
cilMug,
cilMusicNote,
cilNewspaper,
cilNoteAdd,
cilNotes,
cilObjectGroup,
cilObjectUngroup,
cilOpacity,
cilOpentype,
cilOptions,
cilPaintBucket,
cilPaint,
cilPaperPlane,
cilPaperclip,
cilParagraph,
cilPaw,
cilPenAlt,
cilPenNib,
cilPen,
cilPencil,
cilPeople,
cilPhone,
cilPin,
cilPizza,
cilPlant,
cilPlaylistAdd,
cilPlus,
cilPool,
cilPowerStandby,
cilPregnant,
cilPrint,
cilPushchair,
cilPuzzle,
cilQrCode,
cilRain,
cilRectangle,
cilRecycle,
cilReload,
cilReportSlash,
cilResizeBoth,
cilResizeHeight,
cilResizeWidth,
cilRestaurant,
cilRoom,
cilRouter,
cilRowing,
cilRss,
cilRuble,
cilRunning,
cilSad,
cilSatelite,
cilSave,
cilSchool,
cilScreenDesktop,
cilScreenSmartphone,
cilScrubber,
cilSearch,
cilSend,
cilSettings,
cilShareAll,
cilShareAlt,
cilShareBoxed,
cilShare,
cilShieldAlt,
cilShortText,
cilShower,
cilSignLanguage,
cilSignalCellular0,
cilSignalCellular3,
cilSignalCellular4,
cilSim,
cilSitemap,
cilSmilePlus,
cilSmile,
cilSmokeFree,
cilSmokeSlash,
cilSmoke,
cilSmokingRoom,
cilSnowflake,
cilSoccer,
cilSofa,
cilSortAlphaDown,
cilSortAlphaUp,
cilSortAscending,
cilSortDescending,
cilSortNumericDown,
cilSortNumericUp,
cilSpa,
cilSpaceBar,
cilSpeak,
cilSpeaker,
cilSpeech,
cilSpeedometer,
cilSpreadsheet,
cilSquare,
cilStarHalf,
cilStar,
cilStorage,
cilStream,
cilStrikethrough,
cilSun,
cilSwapHorizontal,
cilSwapVertical,
cilSwimming,
cilSync,
cilTablet,
cilTag,
cilTags,
cilTask,
cilTaxi,
cilTennisBall,
cilTennis,
cilTerminal,
cilTerrain,
cilTextShapes,
cilTextSize,
cilTextSquare,
cilTextStrike,
cilText,
cilThumbDown,
cilThumbUp,
cilToggleOff,
cilToggleOn,
cilToilet,
cilTouchApp,
cilTransfer,
cilTranslate,
cilTrash,
cilTriangle,
cilTruck,
cilTv,
cilUnderline,
cilUsb,
cilUserFemale,
cilUserFollow,
cilUserPlus,
cilUserUnfollow,
cilUserX,
cilUser,
cilVector,
cilVerticalAlignBottom,
cilVerticalAlignCenter,
cilVerticalAlignTop,
cilVideo,
cilVideogame,
cilViewColumn,
cilViewModule,
cilViewQuilt,
cilViewStream,
cilVoiceOverRecord,
cilVoice,
cilVolumeHigh,
cilVolumeLow,
cilVolumeOff,
cilWalk,
cilWallet,
cilWallpaper,
cilWarning,
cilWatch,
cilWc,
cilWeightlifitng,
cilWheelchair,
cilWifiSignal0,
cilWifiSignal1,
cilWifiSignal2,
cilWifiSignal3,
cilWifiSignal4,
cilWifiSignalOff,
cilWindowMaximize,
cilWindowMinimize,
cilWindowRestore,
cilWindow,
cilWrapText,
cilXCircle,
cilX,
cilYen,
cilZoomIn,
cilZoomOut,
cilZoom,
} from '@coreui/icons'

export const icons = Object.assign({}, {
  sygnet,
  logo,
  logoNegative
}, {
  cil3d,
  cil4k,
  cilAccountLogout,
  cilActionRedo,
  cilActionUndo,
  cilAddressBook,
  cilAirplaneModeOff,
  cilAirplaneMode,
  cilAirplay,
  cilAlarm,
  cilAlbum,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilAmericanFootball,
  cilAnimal,
  cilAperture,
  cilApple,
  cilApplicationsSettings,
  cilApplications,
  cilAppsSettings,
  cilApps,
  cilArrowBottom,
  cilArrowCircleBottom,
  cilArrowCircleLeft,
  cilArrowCircleRight,
  cilArrowCircleTop,
  cilArrowLeft,
  cilArrowRight,
  cilArrowThickBottom,
  cilArrowThickFromBottom,
  cilArrowThickFromLeft,
  cilArrowThickFromRight,
  cilArrowThickFromTop,
  cilArrowThickLeft,
  cilArrowThickRight,
  cilArrowThickToBottom,
  cilArrowThickToLeft,
  cilArrowThickToRight,
  cilArrowThickToTop,
  cilArrowThickTop,
  cilArrowTop,
  cilAssistiveListeningSystem,
  cilAsteriskCircle,
  cilAsterisk,
  cilAt,
  cilAudioDescription,
  cilAudioSpectrum,
  cilAudio,
  cilAvTimer,
  cilBabyCarriage,
  cilBaby,
  cilBackspace,
  cilBadge,
  cilBalanceScale,
  cilBan,
  cilBank,
  cilBarChart,
  cilBarcode,
  cilBaseball,
  cilBasket,
  cilBasketball,
  cilBath,
  cilBathroom,
  cilBattery0,
  cilBattery3,
  cilBattery5,
  cilBatteryAlert,
  cilBatteryEmpty,
  cilBatteryFull,
  cilBatterySlash,
  cilBeachAccess,
  cilBeaker,
  cilBed,
  cilBellExclamation,
  cilBell,
  cilBike,
  cilBirthdayCake,
  cilBlind,
  cilBluetooth,
  cilBlurCircular,
  cilBlurLinear,
  cilBlur,
  cilBoatAlt,
  cilBold,
  cilBoltCircle,
  cilBolt,
  cilBook,
  cilBookmark,
  cilBorderAll,
  cilBorderBottom,
  cilBorderClear,
  cilBorderHorizontal,
  cilBorderInner,
  cilBorderLeft,
  cilBorderOuter,
  cilBorderRight,
  cilBorderStyle,
  cilBorderTop,
  cilBorderVertical,
  cilBowling,
  cilBraille,
  cilBriefcase,
  cilBrightness,
  cilBritishPound,
  cilBrowser,
  cilBrushAlt,
  cilBrush,
  cilBug,
  cilBuilding,
  cilBullhorn,
  cilBurger,
  cilBurn,
  cilBusAlt,
  cilCalculator,
  cilCalendarCheck,
  cilCalendar,
  cilCameraControl,
  cilCameraRoll,
  cilCamera,
  cilCarAlt,
  cilCaretBottom,
  cilCaretLeft,
  cilCaretRight,
  cilCaretTop,
  cilCart,
  cilCash,
  cilCasino,
  cilCast,
  cilCat,
  cilCc,
  cilCenterFocus,
  cilChartLine,
  cilChartPie,
  cilChart,
  cilChatBubble,
  cilCheckAlt,
  cilCheckCircle,
  cilCheck,
  cilChevronBottom,
  cilChevronCircleDownAlt,
  cilChevronCircleLeftAlt,
  cilChevronCircleRightAlt,
  cilChevronCircleUpAlt,
  cilChevronDoubleDown,
  cilChevronDoubleLeft,
  cilChevronDoubleRight,
  cilChevronDoubleUp,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilChildFriendly,
  cilChild,
  cilCircle,
  cilClearAll,
  cilClipboard,
  cilClock,
  cilClone,
  cilClosedCaptioning,
  cilCloudDownload,
  cilCloudUpload,
  cilCloud,
  cilCloudy,
  cilCode,
  cilCoffee,
  cilCog,
  cilColorBorder,
  cilColorFill,
  cilColorPalette,
  cilColumns,
  cilCommand,
  cilCommentBubble,
  cilCommentSquare,
  cilCompass,
  cilCompress,
  cilContact,
  cilContrast,
  cilControl,
  cilCopy,
  cilCouch,
  cilCreditCard,
  cilCropRotate,
  cilCrop,
  cilCursorMove,
  cilCursor,
  cilCut,
  cilDataTransferDown,
  cilDataTransferUp,
  cilDeaf,
  cilDelete,
  cilDescription,
  cilDevices,
  cilDialpad,
  cilDiamond,
  cilDinner,
  cilDisabled,
  cilDog,
  cilDollar,
  cilDoor,
  cilDoubleQuoteSansLeft,
  cilDoubleQuoteSansRight,
  cilDrinkAlcohol,
  cilDrink,
  cilDrop,
  cilEco,
  cilEducation,
  cilElevator,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEqualizer,
  cilEthernet,
  cilEuro,
  cilExcerpt,
  cilExitToApp,
  cilExpandDown,
  cilExpandLeft,
  cilExpandRight,
  cilExpandUp,
  cilExposure,
  cilExternalLink,
  cilEyedropper,
  cilFaceDead,
  cilFace,
  cilFactorySlash,
  cilFactory,
  cilFastfood,
  cilFax,
  cilFeaturedPlaylist,
  cilFile,
  cilFilterFrames,
  cilFilterPhoto,
  cilFilterSquare,
  cilFilterX,
  cilFilter,
  cilFindInPage,
  cilFingerprint,
  cilFire,
  cilFlagAlt,
  cilFlightTakeoff,
  cilFlipToBack,
  cilFlipToFront,
  cilFlip,
  cilFlower,
  cilFolderOpen,
  cilFolder,
  cilFont,
  cilFootball,
  cilFork,
  cilFridge,
  cilFrown,
  cilFullscreenExit,
  cilFullscreen,
  cilFunctionsAlt,
  cilFunctions,
  cilGamepad,
  cilGarage,
  cilGem,
  cilGif,
  cilGift,
  cilGlobeAlt,
  cilGolfAlt,
  cilGolf,
  cilGradient,
  cilGrain,
  cilGraph,
  cilGridSlash,
  cilGrid,
  cilGroup,
  cilHamburgerMenu,
  cilHandPointDown,
  cilHandPointLeft,
  cilHandPointRight,
  cilHandPointUp,
  cilHappy,
  cilHd,
  cilHdr,
  cilHeader,
  cilHeadphones,
  cilHealing,
  cilHeart,
  cilHighlighter,
  cilHighligt,
  cilHistory,
  cilHome,
  cilHospital,
  cilHotTub,
  cilHouse,
  cilHttps,
  cilImageBroken,
  cilImagePlus,
  cilImage,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilIndustrySlash,
  cilIndustry,
  cilInfinity,
  cilInfo,
  cilInputHdmi,
  cilInputPower,
  cilInput,
  cilInstitution,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilJustifyRight,
  cilKeyboard,
  cilLan,
  cilLanguage,
  cilLaptop,
  cilLayers,
  cilLeaf,
  cilLemon,
  cilLevelDown,
  cilLevelUp,
  cilLibraryAdd,
  cilLibraryBuilding,
  cilLibrary,
  cilLifeRing,
  cilLightbulb,
  cilLineSpacing,
  cilLineStyle,
  cilLineWeight,
  cilLinkAlt,
  cilLinkBroken,
  cilLink,
  cilListFilter,
  cilListHighPriority,
  cilListLowPriority,
  cilListNumberedRtl,
  cilListNumbered,
  cilListRich,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilLockUnlocked,
  cilLocomotive,
  cilLoop1,
  cilLoopCircular,
  cilLoop,
  cilLowVision,
  cilMagnifyingGlass,
  cilMap,
  cilMediaEject,
  cilMediaPause,
  cilMediaPlay,
  cilMediaRecord,
  cilMediaSkipBackward,
  cilMediaSkipForward,
  cilMediaStepBackward,
  cilMediaStepForward,
  cilMediaStop,
  cilMedicalCross,
  cilMeh,
  cilMemory,
  cilMenu,
  cilMic,
  cilMicrophone,
  cilMinus,
  cilMobileLandscape,
  cilMobile,
  cilMoney,
  cilMonitor,
  cilMoodBad,
  cilMoodGood,
  cilMoodVeryBad,
  cilMoodVeryGood,
  cilMoon,
  cilMouse,
  cilMouthSlash,
  cilMove,
  cilMovie,
  cilMugTea,
  cilMug,
  cilMusicNote,
  cilNewspaper,
  cilNoteAdd,
  cilNotes,
  cilObjectGroup,
  cilObjectUngroup,
  cilOpacity,
  cilOpentype,
  cilOptions,
  cilPaintBucket,
  cilPaint,
  cilPaperPlane,
  cilPaperclip,
  cilParagraph,
  cilPaw,
  cilPenAlt,
  cilPenNib,
  cilPen,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPin,
  cilPizza,
  cilPlant,
  cilPlaylistAdd,
  cilPlus,
  cilPool,
  cilPowerStandby,
  cilPregnant,
  cilPrint,
  cilPushchair,
  cilPuzzle,
  cilQrCode,
  cilRain,
  cilRectangle,
  cilRecycle,
  cilReload,
  cilReportSlash,
  cilResizeBoth,
  cilResizeHeight,
  cilResizeWidth,
  cilRestaurant,
  cilRoom,
  cilRouter,
  cilRowing,
  cilRss,
  cilRuble,
  cilRunning,
  cilSad,
  cilSatelite,
  cilSave,
  cilSchool,
  cilScreenDesktop,
  cilScreenSmartphone,
  cilScrubber,
  cilSearch,
  cilSend,
  cilSettings,
  cilShareAll,
  cilShareAlt,
  cilShareBoxed,
  cilShare,
  cilShieldAlt,
  cilShortText,
  cilShower,
  cilSignLanguage,
  cilSignalCellular0,
  cilSignalCellular3,
  cilSignalCellular4,
  cilSim,
  cilSitemap,
  cilSmilePlus,
  cilSmile,
  cilSmokeFree,
  cilSmokeSlash,
  cilSmoke,
  cilSmokingRoom,
  cilSnowflake,
  cilSoccer,
  cilSofa,
  cilSortAlphaDown,
  cilSortAlphaUp,
  cilSortAscending,
  cilSortDescending,
  cilSortNumericDown,
  cilSortNumericUp,
  cilSpa,
  cilSpaceBar,
  cilSpeak,
  cilSpeaker,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilSquare,
  cilStarHalf,
  cilStar,
  cilStorage,
  cilStream,
  cilStrikethrough,
  cilSun,
  cilSwapHorizontal,
  cilSwapVertical,
  cilSwimming,
  cilSync,
  cilTablet,
  cilTag,
  cilTags,
  cilTask,
  cilTaxi,
  cilTennisBall,
  cilTennis,
  cilTerminal,
  cilTerrain,
  cilTextShapes,
  cilTextSize,
  cilTextSquare,
  cilTextStrike,
  cilText,
  cilThumbDown,
  cilThumbUp,
  cilToggleOff,
  cilToggleOn,
  cilToilet,
  cilTouchApp,
  cilTransfer,
  cilTranslate,
  cilTrash,
  cilTriangle,
  cilTruck,
  cilTv,
  cilUnderline,
  cilUsb,
  cilUserFemale,
  cilUserFollow,
  cilUserPlus,
  cilUserUnfollow,
  cilUserX,
  cilUser,
  cilVector,
  cilVerticalAlignBottom,
  cilVerticalAlignCenter,
  cilVerticalAlignTop,
  cilVideo,
  cilVideogame,
  cilViewColumn,
  cilViewModule,
  cilViewQuilt,
  cilViewStream,
  cilVoiceOverRecord,
  cilVoice,
  cilVolumeHigh,
  cilVolumeLow,
  cilVolumeOff,
  cilWalk,
  cilWallet,
  cilWallpaper,
  cilWarning,
  cilWatch,
  cilWc,
  cilWeightlifitng,
  cilWheelchair,
  cilWifiSignal0,
  cilWifiSignal1,
  cilWifiSignal2,
  cilWifiSignal3,
  cilWifiSignal4,
  cilWifiSignalOff,
  cilWindowMaximize,
  cilWindowMinimize,
  cilWindowRestore,
  cilWindow,
  cilWrapText,
  cilXCircle,
  cilX,
  cilYen,
  cilZoomIn,
  cilZoomOut,
  cilZoom,
}, {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
}, {
  cibSkype,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex,
})
