export default {
    data: [],
    fetched: false,
    name: '',
    codeNumber: '',
    codeType: '',
    scaleFactor: '',
    unitName: '',
    maximum: '',
    minimum: '',
    swcKeys: []
};