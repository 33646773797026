import territory from '../store/territoryState';

const territoryReducer = (state = territory, action) => {
  switch (action.type) {
    case 'TERRITORY_LOAD_DATA':
      return {
        ...state, data: action.data, fetched: true
      }
    case 'TERRITORY_FORM_LOAD':
      return {
        ...state, 
        formName: action.form.name,
      }
    case 'TERRITORY_FIELD_CHANGE':
      return {
        ...state, [action.field]: action.data
      }
    case 'TERRITORY_SHOW_ERROR':
      const { path, message } = action;
      return {
        ...state, path, message
      }
    case 'SET_TERRITORY_CHILDREN':
      return {
        ...state, [action.key]: action.value
      }
    case 'TOGGLE_TERRITORY_SAVED':
      return {
        ...state, regionSaved: action.regionSaved
      }
    default:
      return { ...state };
  }
};

export default territoryReducer;
