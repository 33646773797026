import country from '../store/countryState';

const countryReducer = (state = country, action) => {
  switch (action.type) {
    case 'COUNTRY_LOAD_DATA':
      return {
        ...state, data: action.data, fetched: true
      }
    case 'COUNTRY_FORM_LOAD':
      return {
        ...state, 
        formName: action.form.name,
      }
    case 'COUNTRY_FIELD_CHANGE':
      return {
        ...state, [action.field]: action.data
      }
    case 'COUNTRY_SHOW_ERROR':
      const { path, message } = action;
      return {
        ...state, path, message
      }
    case 'SET_COUNTRY_CHILDREN':
      return {
        ...state, [action.key]: action.value
      }
    case 'TOGGLE_COUNTRY_SAVED':
      return {
        ...state, regionSaved: action.regionSaved
      }
    default:
      return { ...state };
  }
};

export default countryReducer;
