import wtd from '../store/wtdState';

const wtdReducer = (state = wtd, action) => {
  switch (action.type) {
    case 'WTD_LOAD_DATA':
      return {
        ...state, data: action.data, fetched: true
      }
    case 'WTD_FORM_LOAD':
      return {
        ...state, 
        name: action.form.name,
        site: action.form.rgnId,
        plcId: action.form.plcId,
        type: action.form.type,
        plc: action.form.plc,
        wpmiKeys: action.form.wpmiKeys,
        wpmiKey: action.form.wpmiKey,
      }
    case 'WTD_FIELD_CHANGE':
      return {
        ...state, [action.field]: action.data
      }
    case 'WTD_WPM_LOAD':
      return {
        ...state, wpmiKey: action.data
      }
    case 'WTD_SELECT_DATA':
      return {
        ...state, formSelect: action.data
      }
    case 'WTD_SELECT_WPMGROUP':
      return {
        ...state, wpm_group: action.data
      }
    case 'WTD_SELECT_WPMSUBGROUP':
      return {
        ...state, wpm_sub_group: action.data
      }
    case 'WTD_SELECT_WPMITEM':
      return {
        ...state, wpm_item: action.data
      }
    case 'WTD_SHOW_NOTIFICATION':
      return {
        ...state, notification : action.notification
      }
    case 'WTD_RESET_NOTIFICATION':
      return {
        ...state, notification : null
      }
    case 'TOGGLE_WTD_SAVED':
      return {
        ...state, wtdSaved: action.wtdSaved
      }
    default:
      return { ...state };
  }
};

export default wtdReducer;
